<template>
  <div>
    <div class="authorbox" v-if="type==1 && info.Cover">
      <router-link tag="span" :to="{name:'teamsBook',params:{tid:info.Id}}" class="left">
        <img :src="info.Cover" alt />
      </router-link>
      <div class="right">
        <h2 class="titleh2">
          <span class="tit">创作团队:</span>
          <router-link
            tag="span"
            :to="{name:'teamsBook',params:{tid:info.Id}}"
            class="tit2"
          >{{info.Team}}</router-link>
          <el-button
            type="primary"
            class="addteam"
            plain
            round
            size="mini"
            @click="handelJoinTeam(info.Id)"
          >加入该团队</el-button>
        </h2>
        <p class="textp">{{info.Slogan}}</p>
        <div class="teammember">
          <span class="tit">创作成员:</span>
          <span class="imgbox" v-for="item in member">
            <img :src="item.Avatar" />
          </span>
        </div>
      </div>
    </div>
    <div class="authorbox" v-if="type==2 && info.Avatar">
      <span class="left left2">
        <img :src="info.Avatar" alt />
      </span>
      <div class="right">
        <h2 class="titleh2">
          <span class="tit">创作人员:</span>
          <span class="tit2">{{info.Username}}</span>
        </h2>
        <p class="textp">{{info.Intro}}</p>
      </div>
    </div>
    <p v-if="loading" class="loadtextp">加载中...</p>
  </div>
</template>
<script>
import { specialAuthorRequest, teamJoinRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      listData: [],
      loading: true,
      noMore: false,
      type: "",
      info: {},
      member: []
    };
  },
  created() {
    this.getSpecialAuthor();
  },
  computed: {
    Identify() {
      return this.$route.params.Identify || ""; //当前项目id
    }
  },
  methods: {
    async getSpecialAuthor() {
      try {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        console.log(this.Identify);
        const result = await specialAuthorRequest({
          identify: this.Identify
        });
        if (result.status == 200) {
          this.loading = false;
          this.type = result.data.type;
          if (this.type === 2) {
            // 类型 (1 团队，2个人
            this.info = result.data.author.info;
          } else {
            this.info = result.data.author.info;
            this.member = result.data.author.member;
          }
          
        }
      } catch (err) {}
    },
    async joinTeam(tid, reason) {
      try {
        const result = await teamJoinRequest({
          tid: tid,
          reason: reason
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`
          });
        } else {
          this.$message({
            type: "info",
            message: `${result.message}`
          });
        }
      } catch (err) {}
    },
    handelJoinTeam(id) {
      var _this = this;
      this.$prompt("请输入申请理由", "加入该团队", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{1,120}/,
        inputErrorMessage: "请输入申请理由",
        center: true
      })
        .then(({ value }) => {
          _this.joinTeam(id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入"
          });
        });
    }
  }
};
</script>
<style  lang="less" scoped>
.loadtextp {
  text-align: center;
  margin: 15px 0 0;
  line-height: 100px;
  color: #888;
  font-size: 14px;
}
.authorbox {
  margin: 30px 0;
  display: flex;
  .left {
    width: 73px;
    height: 58px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
   .left2 {
width: 70px;
    height: 70px;
    border-radius: 100px;
    overflow: hidden;
   }
  .right {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    cursor: pointer;
    color: #666;
    .titleh2 {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 30px;
      .tit {
        line-height: 30px;
      }
      span {
        margin-right: 15px;
      }
    }
    .tit {
      font-size: 18px;
      float: left;
      line-height: 40px;
      margin-right: 5px;
      color: #222;
    }
    .tit2 {
      color: #2f95e3;
      border-bottom: 1px solid #2f95e3;
    }
    .textp {
      font-size: 13px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .teammember {
      overflow: hidden;
      margin-top: 9px;

      .imgbox {
        width: 40px;
        height: 40px;
        border: 1px solid #e9e9e9;
        border-radius: 50px;
        overflow: hidden;
        float: left;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>